export const companyInfo = {
  address: `5997 Roxie Terrace, Fremont, CA, 94555`,
  ceo_name: `Samuel Ekpe`,
  phone_number: `+1(515) 984 0664`,
  email: `samuel@grupa.io`,
  name: `Platohub Inc.`,
};


export const companyInfoNew = {
  address: `4320 Stevens Creek Boulevard, San Jose, CA; 95129`,
  ceo_name: `Dami Thompson`,
  phone_number: `(515)-984-0664`,
  email: `dami@grupa.io`,
  name: `Platohub Inc.`,
  role: `Cofounder, COO`,
};
