<template>
  <div>
    <div ref="agreementContainer" class="agreementContainer">
      <div style="text-align: justify; padding-right: 10px">
        <div style="text-align: center; margin-bottom: 24px; font-weight: bold">
          GRUPA PLATFORM AGREEMENT
        </div>
        <table class="table" id="table">
          <thead class="thead-light">
            <tr style="font-size: 18px">
              <th scope="col" style="width: 150px">PARTIES</th>
              <th scope="col">{{ project.project_name }}</th>
              <th scope="col">GRUPA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Legal Name</td>
              <td>{{ project.project_name ? project.project_name : "" }}</td>
              <td>{{ companyInfoNew.name }}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {{ project.address ? project.address : "" }}
              </td>
              <td>{{ companyInfoNew.address }}</td>
            </tr>
            <tr>
              <td>Contact</td>
              <td>{{ clientInfo.name }}</td>
              <td>{{ companyInfoNew.ceo_name }}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                {{ clientInfo.phone_number ? clientInfo.phone_number : "" }}
              </td>
              <td>{{ companyInfoNew.phone_number }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ clientInfo.email }}</td>
              <td>{{ companyInfoNew.email }}</td>
            </tr>
          </tbody>
        </table>
        <div class="project-section">
          <table class="table" id="table">
            <thead class="thead-light">
              <tr style="font-size: 18px">
                <th colspan="2" scope="col">SECTION A: PROJECT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 120px">Services</td>
                <td>Product development and software engineering services.</td>
              </tr>
              <tr>
                <td>Service Capacity</td>
                <td>
                  Minimum of
                  {{ project.no_of_engineers ? project.no_of_engineers : "" }}
                  engineer(s)
                </td>
              </tr>
              <tr>
                <td>Initial Term</td>
                <td>3 months</td>
              </tr>
              <tr>
                <td>Product</td>
                <td>{{ project.project_name ? project.project_name : "" }}</td>
              </tr>
              <tr>
                <td>Compensation</td>
                <td>
                  {{
                    project.compensation_id
                      ? grupaCompensation(project.compensation_id)
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>Reservation Fee</td>
                <td>
                  $500 for booking fees. For an initial consultation,
                  evaluation, ideation, and execution planning.
                </td>
              </tr>
              <tr>
                <td>Grupa Saas-Fee</td>
                <td>
                  <b
                    >$0 ( Grupa platform is free to use for management until
                    April 2022)
                  </b>
                </td>
              </tr>
              <tr>
                <td>Implementation Services</td>
                <td>
                  Company will use commercially reasonable efforts to provide
                  the Customer with the services described in the Statement of
                  Work (“SOW”) attached as Exhibit A hereto (“Implementation
                  Services”), and the Customer shall pay Company the
                  Implementation Fee in accordance with the terms herein.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="compensation-section">
          <table class="table" id="table">
            <thead class="thead-light">
              <tr style="font-size: 18px">
                <th colspan="2" scope="col">
                  SECTION B: COMPENSATION STRUCTURE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 120px">TERMS</td>
                <td>
                  <div>
                    <span style="font-weight: bold">CASH :</span>
                    ${{ project.price ? project.price.toLocaleString() : "" }}
                    per professional per month Payable in advance, subject to
                    the terms stated in Section 4 below.
                  </div>
                  <div style="margin-bottom: 15px">
                    <span style="font-weight: bold">Equity:</span>
                    {{ project.equity }} percent
                  </div>
                  <div>
                    <span style="font-weight: bold">Equity Awards :</span>
                    The Customer shall grant to each Consultant Restricted
                    Common stock based on its fully-diluted capitalization
                    amount.
                  </div>
                  <div>
                    <span style="font-weight: bold"
                      >Equity Vesting Schedule :</span
                    >
                    Each equity compensation granted to every Consultant, shall
                    vest on a pro-rata basis monthly over an
                    <span style="font-weight: bold; color: red">
                      18 months period, monthly vesting with a 3-month cliff
                      period
                    </span>
                    , subject to the performance and achievement of targets set
                    by the Customer in its product roadmap.
                  </div>
                </td>
              </tr>
              <tr>
                <!-- Edit startup stage -->
                <td>Startup Stage</td>
                <td>
                  <!-- {{project.startupStage?project.startupStage:0}} -->
                  {{ parseStage(project.startup.stage_category_id) }}
                </td>
              </tr>
              <tr>
                <td>Commitment</td>
                <td>15 to 20hrs per week</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br /><br />
        <div class="expert-section">
          <div class="section-title">SECTION C: EXPERT TEAM</div>
          <table class="table" id="table">
            <thead class="thead-light">
              <tr style="font-size: 18px">
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Hours/Week</th>
                <th scope="col">Cash/Month</th>
                <th scope="col" v-if="project.equity">Equity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in teamDetails.members" :key="index">
                <td>{{ member.name }}</td>
                <td>{{ member.email }}</td>
                <td>{{ member.team_role }}</td>
                <td>{{ project.desired_hours }}</td>
                <td>
                  ${{ project.price ? project.price.toLocaleString() : "" }}
                </td>
                <td v-if="project.equity">{{ project.equity }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="agreement-section">
          <center>
            <div class="section-title"><h5>SAAS SERVICES AGREEMENT</h5></div>
          </center>
          <div class="section-text">
            This SaaS Services Agreement (`Agreement`) is entered into on this
            {{ new Date().getDate() }} day of
            {{ monthName(new Date().getMonth()) }} ,
            {{ new Date().getFullYear() }} (the `Effective Date`) between
            {{ companyInfoNew.name }} with a place of business at
            {{ companyInfoNew.address }} (`Company`), and the Customer listed
            above (`Customer`).
            <u
              >This Agreement includes and incorporates the above Order Form, as
              well as the attached Terms and Conditions, and contains, among
              other things, warranty disclaimers, liability limitations and use
              limitations.</u
            >
            There shall be no force or effect to any different terms of any
            related purchase order or similar form even if signed by the parties
            after the date hereof.
          </div>
          <br />
          <div class="signature-container">
            <div class="grupa-signature">
              <div>
                <h5>{{ companyInfoNew.name }} dba Grupa:</h5>
              </div>
            </div>
            <div class="startup-signature">
              <h5>{{ project.project_name ? project.project_name : "" }}</h5>
              <!-- <div><b>Crackers </b></div> -->
            </div>
          </div>
        </div>

        <table
          style="width: 90%; border: 0px; margin-top: 40px"
          cellspacing="10"
          cellpadding="10"
        >
          <tr>
            <td style="padding: 20px">Name: {{ companyInfoNew.ceo_name }}</td>
            <td style="padding: 20px">
              Name: {{ project.project_name ? project.project_name : "" }}
            </td>
          </tr>

          <tr>
            <td style="padding: 20px">Title: {{ companyInfoNew.role }}</td>
            <td style="padding: 20px">Title:</td>
          </tr>
          <tr>
            <td style="padding: 20px">Sign:</td>
            <td style="padding: 20px">Sign:</td>
          </tr>

          <tr>
            <td style="padding: 20px">Date:</td>
            <td style="padding: 20px">Date:</td>
          </tr>
        </table>
        <div class="terms-conditions">
          <center><h2>TERMS AND CONDITIONS</h2></center>
          <div>1. SAAS SERVICES AND SUPPORT</div>
          <div>
            Subject to the terms of this Agreement, Company will use
            commercially reasonable efforts to provide Customers the Services
            and customer support as required for the execution. As part of the
            registration process, the Customer will identify an administrative
            user name and password for the Customer’s Company account. The
            Company reserves the right to refuse registration of or cancel
            passwords it deems inappropriate.
          </div>
          <div class="restriction-responsibility title-header">
            RESTRICTIONS AND RESPONSIBILITIES
          </div>
          <div>
            1.<span style="padding-left: 10px"
              >&nbsp;&nbsp;The Customer hereby engages the Consultant and the
              Consultant hereby agrees to be engaged by the Customer to develop
              the Software in accordance with the specifications attached hereto
              as Exhibit A (the “Statement of Work”). The Consultant shall
              complete the development of the Software according to the
              milestones described on the form attached hereto as Exhibit
              A.</span
            >
          </div>
          <div class="section-gap">
            2.<span style="padding-left: 10px"
              >&nbsp;&nbsp;The Consultant affirms as follows: <br />
              a.&nbsp;&nbsp;that undertaking these Services or any service on
              the Grupa platform have no conflict with their existing jobs,<br />
              b.&nbsp;&nbsp;that they will not use any resources, equipment or
              licenses owned by any third party to carry out this Service,
              and<br />
              c.&nbsp;&nbsp;that taking up the project will not cause them to
              reveal any Intellectual Property belonging to any third party or
              breach any employment agreement to which they are a party
              to.</span
            >
          </div>
          <div class="section-gap" style="padding-left: 20px">
            1.<span style="padding-left: 10px"
              >&nbsp;&nbsp;Customer represents, covenants and warrants that
              Customer will use the Services only in compliance with Company’s
              standard published policies then in effect (the “Policy”) and all
              applicable laws and regulations. Customer hereby agrees to
              indemnify and hold harmless Company against any damages, losses,
              liabilities, settlements, and expenses (including without
              limitation costs and attorneys’ fees) in connection with any claim
              or action that arises from an alleged violation of the foregoing
              or otherwise from Customer’s use of Services. Although Company has
              no obligation to monitor the Customer’s use of the Services,
              Company may do so and may prohibit any use of the Services it
              believes may be (or alleged to be) in violation of the
              foregoing.</span
            >
          </div>
          <div class="section-gap" style="padding-left: 20px">
            2.<span style="padding-left: 10px"
              >&nbsp;&nbsp;Except where provided by the Company, the Customer
              shall be responsible for obtaining and maintaining any equipment
              and ancillary services needed to connect to, access or otherwise
              use the Services, including, without limitation, modems, hardware,
              servers, software, operating systems, networking, web servers, and
              the like (collectively, “Equipment”). Customer shall also be
              responsible for maintaining the security of the Equipment,
              Customer account, passwords (including but not limited to
              administrative and user passwords) and files, and for all uses of
              a Customer account or the Equipment with or without Customer’s
              knowledge or consent.</span
            >
          </div>
        </div>
        <div class="property-law" style="margin-top: 15px">
          <br />
          <div class="title-header">1 .CONFIDENTIALITY; PROPRIETARY RIGHTS</div>
          <div class="section-text">
            1<span style="padding-left: 10px">
              Each party (the “Receiving Party”) understands that the other
              party (the “Disclosing Party”) has disclosed or may disclose
              business, technical or financial information relating to the
              Disclosing Party’s business (hereinafter referred to as
              “Proprietary Information” of the Disclosing Party). Proprietary
              Information of the Company includes non-public information
              regarding features, functionality, and performance of the Service.
              Proprietary Information of Customer includes any information
              relating to the business operations, processes, plans, intentions,
              product information, know-how, design rights, trade secrets,
              software, market opportunities, customers and affairs of the
              Parties, non-public data provided by Customer to Company to enable
              the provision of the Services (“Customer Data”). The Receiving
              Party agrees: (i) to take reasonable precautions to protect such
              Proprietary Information, and (ii) not to use (except in
              performance of the Services or as otherwise permitted herein) or
              divulge to any third person any such Proprietary Information. The
              Disclosing Party agrees that the foregoing shall not apply with
              respect to any information after five (5) years following the
              disclosure thereof or any information that the Receiving Party can
              document (a) is or becomes generally available to the public, or
              (b) was in its possession or known by it prior to receipt from the
              Disclosing Party, or (c) was rightfully disclosed to it without
              restriction by a third party, or (d) was independently developed
              without the use of any Proprietary Information of the Disclosing
              Party or (e) is required to be disclosed by law.
            </span>
          </div>
          <div class="section-text">
            2<span style="padding-left: 10px"
              >Customer shall own all right, title, and interest in and to the
              Customer Data, as well as any data that is based on or derived
              from the Customer Data and provided to Customer as part of the
              Services. Company shall own and retain all right title, and
              interest in and to (a) the Services and Software, all
              improvements, enhancements or modifications thereto, (b) any
              software, applications, inventions, or other technology developed
              in connection with Implementation Services or support, and (c) all
              intellectual property rights related to any of the
              foregoing.</span
            >
          </div>
          <div class="section-text">
            3<span style="padding-left: 10px"
              >Notwithstanding anything to the contrary, Company shall have the
              right to collect and analyze data and other information relating
              to the provision, use, and performance of various aspects of the
              Services and related systems and technologies (including, without
              limitation, information concerning Customer Data and data derived
              therefrom), and Company will be free (during and after the term
              hereof) to (i) use such information and data to improve and
              enhance the Grupa platform and for other development, diagnostic
              and corrective purposes in connection with the Grupa platform and
              other Company offerings, and (ii) disclose such data solely in
              aggregate or other de-identified forms in connection with its
              business.] No rights or licenses are granted except as expressly
              set forth herein.</span
            >
          </div>
        </div>
        <!--  -->
        <div class="information-section">
          <div class="title-header">1 .PAYMENT OF FEES</div>
          <div class="section-text" style="padding-left: 20px">
            1.&nbsp;&nbsp; Customer will pay Company the then applicable fees
            described in the Order Form or its Grupa dashboard for the Services
            and Implementation Services in accordance with the terms therein
            (the “Fees”). If Customer’s use of the Services exceeds the Service
            Capacity set forth on the Order Form or otherwise requires the
            payment of additional fees (per the terms of this Agreement),
            Customer shall be billed for such usage and Customer agrees to pay
            the additional fees in the manner provided herein. The company
            reserves the right to change the Fees or applicable charges and to
            institute new charges and Fees at the end of the Initial Service
            Term or than the current renewal term, upon thirty (30) days prior
            notice to the Customer (which may be sent by email). If Customer
            believes that Company has billed Customer incorrectly, Customer must
            contact Company no later than 60 days after the closing date on the
            first billing statement in which the error or problem appeared, in
            order to receive an adjustment or credit. Inquiries should be
            directed to the Company's customer support department.
          </div>
          <div class="section-text" style="padding-left: 20px">
            2.&nbsp;&nbsp; Where payment of fees is not made by direct credit or
            debit card, the Company may choose to bill through an invoice, in
            which case, full payment for invoices issued in any given month must
            be received by the Company thirty (30) days after the mailing date
            of the invoice. Unpaid amounts are subject to a finance charge of
            1.5% per month on any outstanding balance, or the maximum permitted
            by law, whichever is lower, plus all expenses of collection and may
            result in immediate termination of Service. Customer shall be
            responsible for all taxes associated with Services other than U.S.
            taxes based on Company’s net income.
          </div>
        </div>
        <div class="information-section">
          <div class="title-header">2 .TERM AND TERMINATION</div>
          <div class="section-text">
            1.<span style="padding-left: 10px"
              >&nbsp;&nbsp; Subject to earlier termination as provided below,
              this Agreement is for the Initial Service Term as specified in the
              Order Form, and shall be automatically renewed for additional
              periods of the same duration as the Initial Service Term
              (collectively, the “Term”), unless either party requests
              termination at least thirty (30) days prior to the end of the
              then-current term.</span
            >
          </div>
          <div class="section-text">
            2.<span style="padding-left: 10px"
              >&nbsp;&nbsp; In addition to any other remedies, either party may
              also terminate this Agreement by giving 7 days’ notice, if the
              other party materially breaches any of the terms or conditions of
              this Agreement. Customers will pay in full for the Services up to
              and including the last day on which the Services are provided.
              Upon any termination, Company will make all Customer Data
              available to Customer for electronic retrieval for a period of
              thirty (60) days, but thereafter Company may, but is not obligated
              to, delete stored Customer Data. All sections of this Agreement
              which by their nature should survive termination will survive
              termination, including, without limitation, accrued rights to
              payment, confidentiality obligations, warranty disclaimers, and
              limitations of liability.</span
            >
          </div>
        </div>
        <div class="information-section">
          <div class="title-header">3 .WARRANTY AND DISCLAIMER</div>
          <div class="section-text">
            The Company and the Consultant shall use reasonable efforts
            consistent with prevailing industry standards to maintain the
            Services in a manner that minimizes errors and interruptions in the
            Services and shall perform the Services in a professional and
            workmanlike manner. Services may be temporarily unavailable for
            scheduled maintenance or for unscheduled emergency maintenance,
            either by Company or by third-party providers, or because of other
            causes beyond Company’s reasonable control, but Company shall use
            reasonable efforts to provide advance notice in writing or by e-mail
            of any scheduled service disruption. HOWEVER, THE COMPANY DOES NOT
            WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; NOR
            DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED
            FROM THE USE OF THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN THIS
            SECTION, THE SERVICES AND IMPLEMENTATION SERVICES ARE PROVIDED “AS
            IS” AND THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
            AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
          </div>
        </div>
        <div class="information-section" style="margin-top: 45px">
          <div class="title-header">1 .INDEMNITY</div>
          <div class="section-text">
            Company shall hold Customer harmless from liability to third parties
            resulting from infringement by the Service of any United States
            patent or any copyright or misappropriation of any trade secret,
            provided Company is promptly notified of any and all threats,
            claims, and proceedings related thereto and given reasonable
            assistance and the opportunity to assume sole control over defense
            and settlement; Company will not be responsible for any settlement
            it does not approve in writing. The foregoing obligations do not
            apply with respect to portions or components of the Service (i) not
            supplied by Company, (ii) made in whole or in part in accordance
            with Customer specifications, (iii) that are modified after delivery
            by Company, (iv) combined with other products, processes or
            materials where the alleged infringement relates to such
            combination, (v) where Customer continues allegedly infringing
            activity after being notified thereof or after being informed of
            modifications that would have avoided the alleged infringement, or
            (vi) where Customer’s use of the Service is not strictly in
            accordance with this Agreement. If, due to a claim of infringement,
            the Services are held by a court of competent jurisdiction to be or
            are believed by Company to be infringing, Company may, at its option
            and expense (a) replace or modify the Service to be non-infringing
            provided that such modification or replacement contains
            substantially similar features and functionality, (b) obtain for
            Customer a license to continue using the Service, or (c) if neither
            of the foregoing is commercially practicable, terminate this
            Agreement and Customer’s rights hereunder and provide Customer a
            refund of any prepaid, unused fees for the Service.
          </div>
        </div>
        <div class="information-section">
          <div class="title-header">1 .LIMITATION OF LIABILITY</div>
          <div class="section-text">
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY
            OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO
            ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
            REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE
            OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR
            TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE,
            STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF
            USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF
            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF
            BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY’S
            REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH
            AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY
            CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12
            MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH
            CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.
          </div>
        </div>
        <div class="information-section">
          <div class="title-header">1 .MISCELLANEOUS</div>
          <div class="section-text">
            If any provision of this Agreement is found to be unenforceable or
            invalid, that provision will be limited or eliminated to the minimum
            extent necessary so that this Agreement will otherwise remain in
            full force and effect and enforceable. This Agreement is not
            assignable, transferable, or sublicensable by Customer except with
            Company’s prior written consent. The Company may transfer and assign
            any of its rights and obligations under this Agreement without
            consent. This Agreement is the complete and exclusive statement of
            the mutual understanding of the parties and supersedes and cancels
            all previous written and oral agreements, communications, and other
            understandings relating to the subject matter of this Agreement, and
            all waivers and modifications must be in a writing signed by both
            parties, except as otherwise provided herein. No agency,
            partnership, joint venture, or employment is created as a result of
            this Agreement and the Customer does not have any authority of any
            kind to bind the Company in any respect whatsoever. In any action or
            proceeding to enforce rights under this Agreement, the prevailing
            party will be entitled to recover costs and attorneys’ fees. All
            notices under this Agreement will be in writing and will be deemed
            to have been duly given when received if personally delivered; when
            receipt is electronically confirmed, if transmitted by facsimile or
            e-mail; the day after it is sent for next day delivery by recognized
            overnight delivery service; and upon receipt, if sent by certified
            or registered mail, return receipt requested. This agreement shall
            not be modified or amended except by mutual agreement of the
            parties.
          </div>
        </div>

        <div class="information-section" style="margin-top: 34px">
          <div class="title-header">9 . FORCE MAJEURE</div>
          <div class="section-text">
            If the performance of this Agreement or any obligation under this
            Agreement is prevented, restricted, or interfered with by causes
            beyond either Party's reasonable control ("Force Majeure"), and if a
            Party is unable to carry out its obligations and give the other
            Party prompt written notice of such an event to the Customer and
            Company within two days of such occurrence of a Force Majeure event,
            then the obligations of the Party invoking this provision shall be
            suspended to the extent necessary by such an event. The term Force
            Majeure shall include, without limitation, acts of God, fire,
            explosion, vandalism, storm or other similar occurrence, orders or
            acts of military or civil authority or by national emergencies,
            insurrections, riots, or wars, or strikes, lock-outs, work
            stoppages, or other labor disputes. The excused party shall use
            reasonable efforts under the circumstances to avoid or remove such
            causes of non-performance and shall proceed to perform with
            reasonable dispatch whenever such causes are removed or ceased. An
            act or omission shall be deemed within the reasonable control of a
            party if committed, omitted, or caused by such party, or its
            employees, officers, agents, or affiliates.
          </div>
        </div>
        <div class="information-section">
          <div class="title-header">10. GOVERNING LAW</div>
          <div class="section-text">
            This Agreement shall be governed by the laws of the State of
            Delaware without regard to its conflict of laws provisions. Any
            disputes arising between the parties shall be resolved amicably
            amongst themselves and where amicable resolution is not reached for
            45 days, the parties shall be referred to mediation or the Courts of
            Chancery, Delaware.
          </div>
        </div>

        <div class="information-section">
          <div class="title-header" style="padding-left: 20px">EXHIBIT A</div>
          <div class="title-header" style="padding-left: 20px">
            Statement of Work - Job to be performed
          </div>

          <div class="padding:10px;">
            <div>1.&nbsp;&nbsp;Product Planning</div>
            <div>2.&nbsp;&nbsp;Design Sprint</div>
            <div>
              3.&nbsp;&nbsp;Custom product development work: Iterative product
              development ( based on agreed product roadmap )
            </div>
            <div>
              4.&nbsp;&nbsp;Platform Production Management: Workflow system to
              manage
            </div>

            <div style="padding-left: 20px">
              <div>●&nbsp;&nbsp;Product Roadmapping</div>
              <div>●&nbsp;&nbsp;Product Engineering</div>
              <div>●&nbsp;&nbsp;Execution Management</div>
            </div>
            <div>
              5.&nbsp;&nbsp;Delivery Intelligence: system to recommend and track
              timeline.
            </div>
          </div>
        </div>

        <div class="information-section" style="page-break-after: always">
          <center>
            <div class="title-header">EXHIBIT B</div>
            <div class="title-header">Assignment of Intellectual Property.</div>
          </center>
          <div class="section-text">
            Subject to receipt of full payment from Customer for work done,
            Customer shall own, and Consultant hereby irrevocably transfers to
            Customer, all rights, titles, and interests in and to all
            Deliverables created by Consultant for Customer, as set forth in the
            applicable SOW, and any intellectual property rights connected
            therewith, including, but not limited to all ideas, inventions,
            discoveries, know-how, computer programs, improvements, methods,
            developments, and other creative works and works of authorship,
            whether or not patentable or copyrightable, conceived or made or
            reduced to practice (whether solely or with the participation of
            others) by Consultant. Consultant shall not retain the right to
            reuse or incorporate, without the written consent of Customer, its
            technology, Pre-existing works, work product, and intellectual
            property provided in the Deliverables or the development of the
            Deliverables. If any Deliverables falls within the definition of
            “work made for hire” in the U. S. Copyright Act, 17 U.S.C. §101 et
            seq., then for purposes of this Agreement such Deliverables shall be
            deemed to be a “work made for hire,” the copyright of which shall be
            owned solely and exclusively by Customer. If Consultant would be
            deemed to retain by operation of law or otherwise any intellectual
            property rights in any such Deliverables, then Consultant hereby
            assigns and transfers all such retained intellectual property rights
            to Customer and its successors. where the Consultant has retained
            any rights in the Deliverables for themselves, or in the event that
            the Consultant is not available to execute any such documents to
            this effect, when required, the Consultant irrevocably appoints the
            Company as its attorney-in-fact to execute all such documents on its
            behalf. <br />
            For purposes of this paragraph, the term “Deliverables” shall mean
            an item to be delivered to the Customer as part of an SOW and shall
            exclude any Consultant or third party proprietary technology
            (including software, methodologies, and tools) already in existence
            at the time of this Agreement, and which has not been developed
            under this Agreement for Customer. Notwithstanding the foregoing,
            Consultant will provide Customer with prior written notice
            identifying any such Consultant or third party proprietary
            technology to be provided to Customer under any Statement of Work.
            In such instances, title and full ownership rights shall remain with
            Consultant or such third party and Consultant hereby grants a fully
            paid, perpetual, irrevocable license to Customer to use, modify,
            create derivative works and otherwise commercially exploit
            Consultant’s proprietary technology in conjunction with the
            Deliverables.
          </div>
          <br /><br />
          <div class="section-title">
            As used in this Agreement, the term “Pre-Existing Works” shall mean
            all the Applications developed by the Customer or its associates,
            copyrights, patents, trade secrets, or other intellectual property
            rights owned by or licensed to Consultant prior to the date of this
            Agreement. Ownership of the Pre-Existing Works and work products
            shall remain with the Customer. Without Customer’s prior written
            permission, Consultant shall not itself, or through any other party
            use the Deliverables to provide Services, processing services, or
            “service bureau” services to third parties; or remove any copyright
            notices from any Deliverables.
          </div>
        </div>

        <div class="information-section">
          <div class="title-header">OBLIGATIONS OF THE PARTIES</div>

          <div class="title-header" style="padding-left: 20px">
            1.1 OBLIGATIONS OF THE CONSULTANT
          </div>
          <div style="padding-left: 40px">
            <div class="section-text">
              1.1.1 Develop products or software in line with the contents of
              the Statement of Work, and shall unfailingly make daily and weekly
              stand-ups to the Customer.
            </div>
            <div class="section-text">
              1.1.2 Provide effective communication to the Customer regarding
              the Product and any queries or questions that may arise;
            </div>
            <div class="section-text">
              1.1.3 Designates a Lead Consultant who will be the primary contact
              for the Customer.
            </div>
            <div class="section-text">
              1.1.4 Be required to respond to any questions, issues, queries,
              and or concerns within 24 hours of communication from the Customer
              regarding documentation and requirements.
            </div>
            <div class="section-text">
              1.1.5 work together in consensus to achieve set company goals and
              targets.
            </div>
          </div>
        </div>
        <div class="information-section">
          <div class="title-header" style="padding-left: 20px">
            1.2 OBLIGATIONS OF THE CUSTOMER
          </div>
          <div style="padding-left: 40px">
            <div class="section-text">
              1.2.1 immediately upon execution of this Agreement, provide all
              relevant information including but not limited to the scope of
              work for this Product and other requirements pertaining to the
              Product.
            </div>
            <div class="section-text">
              1.2.2. within 24 hours of communication from the team required to
              respond to questions, issues, queries, and or concerns regarding
              documentation and requirements
            </div>
            <div class="section-text">
              1.2.3 promptly and upon completion of the Product pay, the full
              and final Consideration based on the Grupa.io service plan
              selected.
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import { companyInfoNew } from "@/constants";
import { grupaCompensation } from "@/utils/grupaVariables.js";
export default {
  props: {
    teamDetails: {
      required: true,
      type: Object,
    },
    project: {
      required: true,
      type: Object,
    },
  },
  components: {},

  data: () => ({
    companyInfoNew: companyInfoNew,
    grupaCompensation: grupaCompensation,
  }),

  created() {},
  methods: {
    parseStage(a) {
      try {
        if (a == null) {
          return "";
        }
        switch (a) {
          case 1:
            return "Angel";
          case 2:
            return "Pre-Seed";
          case 3:
            return "Seed";
          case 4:
            return "Series A";

          default:
            return "";
        }
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    monthName(num) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[num];
    },
  },
  computed: {
    clientInfo() {
      if (this.project.owner && this.project.owner.name) {
        const { name, email } = this.project.owner;
        return { name, email };
      } else {
        return { name: "", email: "" };
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.agreementContainer {
  /* max-width: 940px; */
  margin: -1% 0 30px 0;
  padding: 0px;
  clear: both;
  margin-top: 0px !important;
}
.compensation-section,
.project-section {
  margin-top: 24px;
}
.signature-container {
  display: flex;
}
.startup-signature,
.grupa-signature {
  width: 50%;
}

.information-section,
.restriction-responsibility,
.terms-conditions,
.agreement-section {
  margin-top: 32px;
}
.section-gap {
  margin-top: 24px;
}
.title-header {
  font-weight: bold;
}
.section-text {
  margin-top: 12px;
}
</style>
